















import { post, get } from '@/services/api'

const formatByExt = {
  json: {
    type: 'ebngl',
    label: 'eBNGL',
  },
  ebngl: {
    type: 'ebngl',
    label: 'eBNGL',
  },
  bngl: {
    type: 'bngl',
    label: 'BNGL',
  },
  xml: {
    type: 'sbml',
    label: 'SBML',
  },
}

export default {
  name: 'model-import',
  data() {
    return {
      allowedExtensions: Object.keys(formatByExt),
      loading: false,
    }
  },
  methods: {
    async beforeUpload(file) {
      this.loading = true
      let model: any

      const user_id = this.$store.state.user?.id
      if (!user_id) return

      this.modelName = file.name.split('.').slice(0, -1).join('.')
      const format = file.name.split('.').slice(-1)[0].toLowerCase()

      if (format === 'ebngl') {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          async () => {
            const r = JSON.parse(reader.result as string)
            const model = await post('clone-model', {
              model_id: r['id'],
            })
            if (!model) {
              this.$Notice.error({
                title: 'Import error',
                desc: 'There was an error importing the model',
              })
              this.loading = false
              this.$emit('import-finish')
              return
            }

            this.$store.commit('loadDbModel', model.data)

            this.loading = false
            this.$emit('import-finish')

            const models = await get('models')
            this.$store.commit('updateDbModels', models.data)
          },
          false
        )
        reader.readAsText(file)

        return false
      }
      const form = new FormData()
      form.append('file', file)
      form.append('user_id', user_id)

      model = await post('import-bngl', form)

      this.$store.commit('loadDbModel', model.data)

      this.loading = false
      this.$emit('import-finish')

      const models = await get('models')
      this.$store.commit('updateDbModels', models.data)

      return false
    },
  },
}
