



















































import ModelImport from '@/components/shared/model-import.vue'
import { post, patch, get, del } from '@/services/api'
import { AxiosResponse } from 'axios'
import { ModelBase } from '@/types'
import { PUBLIC_USER_ID } from '@/constants'
import saveAs from 'file-saver'

export default {
  name: 'model-meta',
  components: {
    'model-import': ModelImport,
  },
  data() {
    return {
      pid: PUBLIC_USER_ID,
      importModalVisible: false,
      saveInProgress: false,
      saveBtnLabel: 'Save',
    }
  },
  methods: {
    clearModel() {
      this.$store.commit('loadDbModel', {})
    },
    async cloneModel() {
      if (!this.modelId) return
      const res = await post('clone-model', { model_id: this.modelId })
      this.$store.commit('loadDbModel', res.data)

      this.getModels()
    },
    async saveModel() {
      this.saveInProgress = true
      this.saveBtnLabel = 'Saving'

      const model = this.$store.state.model

      let modelRes: AxiosResponse<ModelBase>

      if (!model?.id || model?.user_id === PUBLIC_USER_ID)
        modelRes = await post('models', { name: this.name, annotation: this.annotation })
      else modelRes = await patch(`models/${model.id}`, { name: this.name, annotation: this.annotation })

      this.$store.commit('loadDbModel', modelRes.data)

      this.saveBtnLabel = 'Saved!'
      this.saveBtnLabel = 'Save'
      this.saveInProgress = false

      this.getModels()
    },

    async getModels() {
      const models = await get('models')
      this.$store.commit('updateDbModels', models.data)
    },

    async deleteModel() {
      if (!this.modelId) return
      const res = await del(`models/${this.modelId}`)
      if (res.status == 200) {
        this.getModels()
        this.clearModel()
      }
    },

    async exportModel(format: string) {
      let res: AxiosResponse<string> | undefined
      if (!this.modelId) return

      if (format === 'bngl') res = await get(`export-model/${this.modelId}`)
      else if (format === 'ebngl') res = await get(`model-detail/${this.modelId}`)

      if (!res) {
        this.$Notice.error({
          title: 'Export error',
        })
        return
      }

      let data = res.data
      if (format === 'ebngl') data = JSON.stringify(data)

      saveAs(new Blob([data]), `${this.name}.${format}`)
    },
    showImportModal() {
      this.importModalVisible = true
    },
    onImportFinished() {
      this.importModalVisible = false
    },
  },
  computed: {
    modelId() {
      return this.$store.state.model?.id
    },

    name: {
      get() {
        return this.$store.state.model.name
      },
      set(name) {
        this.$store.commit('updateModelName', name)
      },
    },
    annotation: {
      get() {
        return this.$store.state.model.annotation
      },
      set(annotation) {
        this.$store.commit('updateModelAnnotation', annotation)
      },
    },
    saveBtnAvailable() {
      return !!this.name
    },
  },
}
